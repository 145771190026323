import { useStaticQuery, graphql } from 'gatsby';

export const GetHome = () => {
  return useStaticQuery(
    graphql`
      query {
        allStrapiHome {
          nodes {
            heading
            intro
          }
        }
      }
    `
  ).allStrapiHome.nodes;
};
