import React from 'react';
import { Link, navigate } from 'gatsby';
import { isLoggedIn } from '../services/auth';
import Layout from '../components/layout';
import SEO from '../components/seo';
import hero from '../assets/images/hero.jpg';
import Row from '../components/row/row';
import signature from '../assets/images/signature-team-jacob.png';
import team from '../assets/images/team-jacob.jpg';
import { GetHome } from '../hooks/home';

const IndexPage = () => {
  if (isLoggedIn()) {
    navigate(`/app/classes`);
  }
  const homeContent = GetHome()[0] ?? {};

  return (
    <Layout>
      <SEO title="Welcome" />
      <img src={hero} className="hero" alt="" />

      <Row>
        <div className="about-container">
          <div className="column">
            {homeContent.heading && <h2>{homeContent.heading}</h2>}
            {homeContent.intro && <p className="h-spacing-bottom">{homeContent.intro}</p>}
            <img src={signature} alt="Team Jacob Signature" width={200} />
          </div>
          <div className="column">
            <img src={team} alt="Team Jacob" />
          </div>
        </div>
      </Row>
      {!isLoggedIn() && (
        <Row marginBottom={50}>
          <ul className="home-actions">
            <li>
              <Link to="/app/login">Log In</Link>
            </li>
            <li>
              <Link to="/join-team" className="button">
                Sign Up
              </Link>
            </li>
            <li>- Or -</li>
            <li>
              <a href="https://www.jointeamjacob.com/" className="button button--secondary">
                Not an Artist
              </a>
            </li>
          </ul>
        </Row>
      )}
    </Layout>
  );
};

export default IndexPage;
